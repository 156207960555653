import * as tasksActions from "./tasksActions";
import * as API from "../../services/api";
import { toast } from "react-toastify";

export const patchAllTasksDaysOperation = () => (dispatch, getState) => {
  dispatch(tasksActions.startPatchAllTasksDaysAction());
  const state = getState();
  const { token } = state.auth;
  const { tasks } = state;

  const tasksForPatchRequestBody = tasks.map((el) => ({
    taskId: el._id,
    days: el.days.map(item => ({
      date: item.date,
      isActive: item.isActive,
      isCompleted: item.isCompleted,
    })),
  }));

  API.patchAllTasksDays(token, { tasks: tasksForPatchRequestBody })
    .then((res) => {
      if (res.data.success) {
        dispatch(tasksActions.successPatchAllTasksDaysAction(res.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        const status = error.response.status;
        if (status === 400) {
          toast.error("Bad request (invalid request body or id) / No token provided");
        } else if (status === 401) {
          toast.error("Unauthorized (invalid token)");
        } else if (status === 404) {
          toast.error("Invalid user / Invalid session / Task not found / Day not found");
        }
        dispatch(tasksActions.errorPatchAllTasksDaysAction(error.response.data));
      } else {
        dispatch(tasksActions.errorPatchAllTasksDaysAction(error));
      }
    });
};

export const patchSingleTaskDaysOperation = (taskId) => (dispatch, getState) => {
  dispatch(tasksActions.startPatchSingleTaskDaysAction());
  const state = getState();
  const { token } = state.auth;
  const { tasks } = state;

  const taskForUpdate = tasks.find(el => el._id === taskId);
  const arrayForUpdate = taskForUpdate.days.map(el => el.isActive);
  const patchData = { days: [...arrayForUpdate] };

  API.patchSingleTaskDays(token, taskId, patchData)
    .then((res) => {
      dispatch(tasksActions.successPatchSingleTaskDaysAction(res.data));
    })
    .catch((error) => {
      if (error.response) {
        const status = error.response.status;
        if (status === 400) {
          toast.error("Bad request (invalid request body or id) / No token provided");
        } else if (status === 401) {
          toast.error("Unauthorized (invalid token)");
        } else if (status === 404) {
          toast.error("Invalid user / Invalid session / Task not found / Day not found");
        }
        dispatch(tasksActions.errorPatchSingleTaskDaysAction(error.response.data));
      } else {
        dispatch(tasksActions.errorPatchSingleTaskDaysAction(error));
      }
    });
};

export const patchSwitchTaskDayOperation = (taskId, date) => (dispatch, getState) => {
  dispatch(tasksActions.startSwitchTaskDayAction());
  const state = getState();
  const { token } = state.auth;

  const patchData = { date: date };

  API.patchTaskSwitchCompletedDay(token, taskId, patchData)
    .then((res) => {
      dispatch(tasksActions.successSwitchTaskDayAction(res.data));
    })
    .catch((error) => {
      if (error.response) {
        const status = error.response.status;
        if (status === 400) {
          toast.error("Bad request (invalid request body or id) / No token provided");
        } else if (status === 401) {
          toast.error("Unauthorized (invalid token)");
        } else if (status === 404) {
          toast.error("Invalid user / Invalid session / Task not found / Day not found");
        }
        dispatch(tasksActions.errorSwitchTaskDayAction(error.response.data));
      } else {
        dispatch(tasksActions.errorSwitchTaskDayAction(error));
      }
    });
};

export const createTaskOperation = (taskData) => async (dispatch, getState) => {
  dispatch(tasksActions.startCreateTaskAction());
  const state = getState();
  const { token } = state.auth;

  API.createTask(token, taskData)
    .then((res) => {
      dispatch(tasksActions.successCreateTaskAction(res.data));
    })
    .catch((error) => {
      if (error.response) {
        const status = error.response.status;
        if (status === 400) {
          toast.error("Bad request (invalid request body or id) / No token provided");
        } else if (status === 401) {
          toast.error("Unauthorized (invalid token)");
        } else if (status === 404) {
          toast.error("Invalid user / Invalid session / Task not found / Day not found");
        }
        dispatch(tasksActions.errorCreateTaskAction(error.response.data));
      } else {
        dispatch(tasksActions.errorCreateTaskAction(error));
      }
    });
};