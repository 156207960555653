import types from "../types";

const initialState = {
  giftArray: [],
  totalPoints: 0,
  purchasedGiftIds: []
};

const giftReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SUCCESS_GET_GIFT:
      const addIsSelectedIfThisFieldNone = (arr) => {
        return arr.map((el) => {
          if (el.isSelected === true || el.isSelected === false) {
            return el;
          } else {
            return ({...el, isSelected: false});
          }
        });
      };
      return ({
        ...state,
        giftArray: addIsSelectedIfThisFieldNone([...payload.giftArray]),
      });

    case types.ERROR_GET_GIFT:
    case types.SUCCESS_LOGOUT:
      return initialState;

    case types.GIFT_CHANGES_TOGGLE_SELECTED:
      const updateAwards = state.giftArray.map((el) =>
        el.id === payload.id ? { ...el, isSelected: !el.isSelected } : el
      );
      const updateTotalPoints = (updateAwards) =>
        updateAwards.reduce(
          (acc, el) => (el.isSelected && acc + el.price) || acc,
          0
        );
      return ({
        ...state,
        giftArray: [...updateAwards],
        totalPoints: updateTotalPoints(updateAwards),
      });

    case types.SUCCESS_RECEIVE_GIFTS:
      return ({
        ...state,
        giftArray: state.giftArray.map(el => ({...el, isSelected: false})),
        purchasedGiftIds: payload.data.purchasedGiftIds,
      });
    
    case types.CLEAR_PURCHASED_GIFT_IDS:
      return ({
        ...state,
        purchasedGiftIds: [],
        totalPoints: 0,
      })

    default:
      return state;
  }
};

export default giftReducer;
