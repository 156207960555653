import React from "react";
import s from "./CardPointAmount.module.css";
import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";

const CardPointAmount = ({ point }) => {
  const { data } = useData();
  const language = data.language;

  const textPoints = () => {
    if (point === 1) return localization.CardPointAmount.score[language];
    if (point > 1 && point <= 4) return localization.CardPointAmount.point[language];
    return localization.CardPointAmount.points[language];
  };

  return (
    <div className={s.point_amount}>
      <p className={s.point_amount_p}>
        {point} {textPoints()}
      </p>
    </div>
  );
};

export default CardPointAmount;
