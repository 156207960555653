import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AuthForm from '../../components/AuthForm/AuthForm';
import Footer from '../../components/FooterAuthPage/FooterAuthPage';
import * as authOperation from '../../redux/auth/authOperation';
import * as authActions from '../../redux/auth/authActions';
import withAuthRedirect from '../../hoc/withAuthRedirect';
import localization from '../../utils/localization';
import { useData } from "../../context/DataContext";
import s from './AuthPage.module.css';

import home1Image from "../../assets/images/authPage/home1.png";
import home2Image from "../../assets/images/authPage/home2.png";
import joystickImage from "../../assets/images/authPage/joystick.png";
import robotImage from "../../assets/images/authPage/robot.png";


const AuthPage = ({ location }) => {
  const { data } = useData();
  const language = data.language;

  const token =
    location.search && new URLSearchParams(location.search).get('token');

  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(authActions.setTokenInStoreAction(token));
      dispatch(authOperation.getUserWithTokenFromUrlOperation(token));
    }
  }, [dispatch, token]);

  return (
    <main className={s.main}>
      <div className={s.content}>
        <div className={s.leftSideContainer}>
          <div className={s.leftSideContent}>
            <img className={s.home1Image} src={home1Image} alt="home1"/>
            <img className={s.joystickImage} src={joystickImage} alt="joystick"/>
            <img className={s.robotImage} src={robotImage} alt="robot"/>
            <img className={s.home2Image} src={home2Image} alt="home2"/>
          </div>
        </div>
        <div className={s.rightSide}>
          <p className={s.text}>{localization.AuthPage.title[language]}</p>
          <AuthForm />
        </div>
        <Footer />
      </div>

        <div className={s.tabletImg1}></div>
        <div className={s.tabletImg2}></div>
        <div className={s.tabletImg3}></div>

        <div className={s.mobileImg1}></div>
    </main>
  );
};

AuthPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default withAuthRedirect(AuthPage);
