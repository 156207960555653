import React from "react";
import s from './AuthButton.module.css';

export const AuthButton = ({ children, props, onClickFunc }) => {
  return (
    <button
      className={s.btn}
      type="submit"
      {...props}
      onClick={onClickFunc}
    >
      {children}
    </button>
  );
};

export default AuthButton;
