import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useUnmount } from 'react-use';
import s from "./PlanningPage.module.css";
import PlanningPoints from "../../components/PlanningPoints/PlanningPoints";
import MobilePlanningPoints from "../../components/MobilePlanningPoints/MobilePlanningPoints";
import CardsList from "../../components/CardsList/CardsList";
import CurrentWeekPlaning from "../../components/CurrentWeekPlaning/CurrentWeekPlaning";
import MobileCurrentWeekPlaning from "../../components/MobileCurrentWeekPlaning/MobileCurrentWeekPlaning";
import AddCustomTask from "../../components/AddCustomTask/AddCustomTask";
import MobileAddCustomTask from "../../components/MobileAddCustomTask/MobileAddCustomTask";
import Footer from "../../components/Footer/Footer";

import * as tasksOperation from "../../redux/tasks/tasksOperation";
import * as tasksSelectors from "../../redux/tasks/tasksSelectors";
import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";

const PlanningPage = () => {
  const { data } = useData();
  const windowInnerWidth = data.windowInnerWidth;
  const language = data.language;

  let tasks = useSelector((store) => tasksSelectors.getTasks(store));

  // change tasks titles and shortTitles to selected application language - start:
  const tasksTitlesArray = localization.WeekTabsContent.tasksTitles.map(
    (el) => el[language]
  );
  const tasksShortTitlesArray = localization.MainPage.days.map((el) => ({
    ...el,
    name: el.name[language],
    shortName: el.shortName[language],
  }));

  tasks = tasks.map((item, idx) => ({
    ...item,
    title: tasksTitlesArray[idx] ? tasksTitlesArray[idx] : item.title,
    days: item.days.map((day, idxD) => ({
      ...day,
      title: tasksShortTitlesArray[idxD].shortName,
    })),
  }));
  // change tasks titles and shortTitles to selected application language - end.

  const dispatch = useDispatch();
  useUnmount(() => {
    //when leaving the planning page, send the status of all days of all times with this operation:
    dispatch(tasksOperation.patchAllTasksDaysOperation());
  });

  return (
    <div className={s.planningContainer}>
      {windowInnerWidth < 768 && <MobileCurrentWeekPlaning />}
      {windowInnerWidth >= 768 && (
        <div className={s.pointsTaskContainer}>
          <CurrentWeekPlaning />
          <div className={s.secondTaskContainer}>
            <>
              <PlanningPoints />
              <AddCustomTask />
            </>
          </div>
        </div>
      )}
      <div className={s.list}>{tasks && <CardsList tasks={tasks} />}</div>

      {windowInnerWidth < 768 && (
        <div className={s.MobilePointsTaskContainer}>
          <MobilePlanningPoints />
          <MobileAddCustomTask />
        </div>
      )}

      <Footer />
    </div>
  );
};

export default PlanningPage;
