import React from "react";
import s from "./ModalLogout.module.css";
import ModalBackdropWrapper from "../ModalBackdropWrapper/ModalBackdropWrapper";
import { useDispatch } from "react-redux";
import * as authOperations from "../../redux/auth/authOperation";
import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";

const ModalLogout = ({ onClose }) => {
  const { data } = useData();
  const language = data.language;

  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(authOperations.logoutOperation());
    onClose();
  };

  return (
    <>
      <ModalBackdropWrapper onClose={onClose}>
        <div className={s.modalContent}>
          <h2 className={s.modalTitleText}>
            {localization.ModalLogout.question[language]}
          </h2>

          <div className={s.buttonContainer}>
            <button className={s.btn} onClick={onLogout}>
              {localization.ModalLogout.yes[language]}
            </button>
            <button onClick={onClose} className={s.btn}>
              {localization.ModalLogout.no[language]}
            </button>
          </div>
        </div>
      </ModalBackdropWrapper>
    </>
  );
};

export default ModalLogout;
