import React from "react";
import { ReactComponent as Box } from "../../assets/icons/present box/gift-box.svg";
import s from "./AwardsTitle.module.css";

import localization from "../../utils/localization";
import { useData } from "../../context/DataContext";

const AwardsTitle = () => {
  const { data } = useData();
  const language = data.language;

  return (
    <div className={s.present_item}>
      <Box className={s.box} />
      <h2 className={s.title}>{localization.AwardsTitle.myPrizes[language]}</h2>
    </div>
  );
};

export default AwardsTitle;
