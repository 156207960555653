import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";

// axios.defaults.baseURL = "https://kidslikev1.herokuapp.com";
axios.defaults.baseURL = "https://kidslike-v1-backend.goit.global";

export const setToken = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const registerUser = (data) => axios.post("/auth/register", data);
export const loginUser = (data) => axios.post("/auth/login", data);

export const logoutUser = (token) =>
  axios.post("/auth/logout", {}, setToken(token));

export const getUser = (token) => axios.get("/user/info", setToken(token));

export const getGift = (token, language = null) => {
  if (language === "en") {
    return axios.get("/gift/en", setToken(token));
  } else if (language === "pl") {
    return axios.get("/gift/en", setToken(token));
  } else {
    // return gifts on ru language
    return axios.get("/gift", setToken(token));
  }
};

export const patchAllTasksDays = (token, data) => {
  return axios.patch("/task/active", data, setToken(token));
};

export const patchSingleTaskDays = (token, taskId ,data) => {
  return axios.patch(`/task/single-active/${taskId}`, data, setToken(token));
};

export const patchTaskSwitchCompletedDay = (token, taskId ,data) => {
  return axios.patch(`/task/switch/${taskId}`, data, setToken(token));
};

export const createTask = (token, data) => {
  const formData = new FormData();
  formData.append('title', data.title);
  formData.append('reward', data.reward);
  if(data.file) {
    formData.append("file", data.file);
  }

  return axios.post(`/task`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: 'multipart/form-data'
    },
  });
};

export const patchReceiveGifts = (token ,data) => {
  return axios.patch('/gift', data, setToken(token));
};