import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "../../routes/routes";
import * as authSelectors from "../../redux/auth/authSelectors";
import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";
import s from "./Navigation.module.css";

const routesObject = routes.reduce(
  (acc, el) => ({ ...acc, [el.name]: el.path }),
  {}
);

const Navigation = () => {
  const { data } = useData();
  const language = data.language;

  const isAuth = useSelector((store) => authSelectors.getIsAuth(store));

  return (
    <ul className={s.navigationList}>
      {!isAuth && (
        <li className={s.navigationItem}>
          <NavLink
            exact
            className={s.navigationLink}
            activeClassName={s.activeNavigationLink}
            to={routesObject.AuthPage}
          >
            {localization.Navigation.auth[language]}
          </NavLink>
        </li>
      )}
      {isAuth && (
        <>
          <li className={s.navigationItem}>
            <NavLink
              exact
              className={s.navigationLink}
              activeClassName={s.activeNavigationLink}
              to={routesObject.MainPage}
            >
              {localization.Navigation.main[language]}
            </NavLink>
          </li>
          <li className={s.navigationItem}>
            <NavLink
              exact
              className={s.navigationLink}
              activeClassName={s.activeNavigationLink}
              to={routesObject.PlanningPage}
            >
              {localization.Navigation.planning[language]}
            </NavLink>
          </li>
          <li className={s.navigationItem}>
            <NavLink
              exact
              className={s.navigationLink}
              activeClassName={s.activeNavigationLink}
              to={routesObject.AwardsPage}
            >
              {localization.Navigation.awards[language]}
            </NavLink>
          </li>
        </>
      )}
      {/* <li className={s.navigationItem}>
        <NavLink
          exact
          className={s.navigationLink}
          activeClassName={s.activeNavigationLink}
          to={routesObject.ContactsPage}
        >
          {localization.Navigation.contacts[language]}
        </NavLink>
      </li> */}
    </ul>
  );
};

export default Navigation;
