import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as authSelectors from "../../redux/auth/authSelectors";
import ModalLogout from '../ModalLogout/ModalLogout';
import Logo from "../Logo/Logo";
import Navigation from "../Navigation/Navigation";
import UserInfo from "../UserInfo/UserInfo";
import HeaderNavigationModal from "../HeaderNavigationModal/HeaderNavigationModal";
import Burger from "../SvgSymbols/Burger";
import s from "./Header.module.css";

const Header = () => {
  const [
    isShowHeaderNavigationModal,
    setIsShowHeaderNavigationModal,
  ] = useState(false);
  const openHeaderNavigationModal = () => {
    setIsShowHeaderNavigationModal(true);
  };
  const closeHeaderNavigationModal = () => {
    setIsShowHeaderNavigationModal(false);
  };

  const userIsAuth = useSelector((store) => authSelectors.getIsAuth(store));

  const [
    isShowModalLogout,
    setIsShowModalLogout,
  ] = useState(false);
  const openModalLogout = () => {
    setIsShowModalLogout(true);
  };
  const closeModalLogout = () => {
    setIsShowModalLogout(false);
  };

  return (
    <header className={s.headerContainer}>
      <div className={s.headerContent}>
        <div>
          <Logo />
        </div>

        <div className={s.container}>
            <>
              {/* Navigation shows over 860px: */}
              <Navigation />
              {userIsAuth && <UserInfo openModalLogout={openModalLogout} />}
            </>

            <>
              {/* HeaderNavigationModal and BurgerIcon shows up to 860 pixels: */}
              <button className={s.burgerMenu} onClick={openHeaderNavigationModal}>
                <Burger />
              </button>
              {isShowHeaderNavigationModal && (
                <HeaderNavigationModal openModalLogout={openModalLogout} onClose={closeHeaderNavigationModal} />
              )}
            </>
        </div>
      </div>

      {isShowModalLogout && <ModalLogout onClose={closeModalLogout} />}
    </header>
  );
};

export default Header;
