const localization = {
  AuthPage: {
    title: {
      ru: "Выполняй задания, получи классные призы!",
      en: "Complete tasks, get cool prizes!",
      pl: "Rozwiązuj zadania, otrzymuj super nagrody!",
    },
  },

  AuthForm: {
    logInWithGoogleText: {
      ru: "Вы можете авторизоваться с помощью Google Account:",
      en: "You can log in with your Google Account:",
      pl: "Możesz przejść autoryzację za pomocą Google Account:",
    },
    enterWithEmailAndPasswordText: {
      ru:
        "Или зайти с помощью e-mail и пароля, предварительно зарегистрировавшись:",
      en: "Or log in using your e-mail and password, after registering:",
      pl:
        "Lub zalogować się za pomocą adresu e-mail i hasła, po uprzednim zarejestrowaniu się:",
    },
    errors: {
      isNotEmail: {
        ru: "Электронная почта должна иметь правильный формат",
        en: "Email should have correct format",
        pl: "Email powinien mieć prawidłowy format",
      },
      emailRequiredField: {
        ru: "Электронная почта - обязательное поле",
        en: "Email is a required field",
        pl: "Email jest polem wymaganym",
      },
      passwordRequiredField: {
        ru: "Пароль - обязательное поле",
        en: "Password is required field",
        pl: "Hasło jest wymagane",
      },
      passwordMustContain5Symbols: {
        ru: "Пароль должен сождержать минимум 5 символов",
        en: "Password must contain at least 5 characters",
        pl: "Hasło musi zawierać co najmniej 5 znaków",
      },
      passwordMustContainMaximum30Symbols: {
        ru: "Пароль должен сождержать максимум 30 символов",
        en: "Password must contain a maximum of 30 characters",
        pl: "Hasło musi zawierać maksymalnie 30 znaków",
      },
      userWithEmailAlreadyExist: {
        ru: "Пользователь с этим адресом электронной почты уже существует",
        en: "User with this email already exists",
        pl: "Użytkownik z tym adresem email już istnieje",
      },
      emailNotExist: {
        ru: "Пользователя с таким адресом электронной почты не существует",
        en: "A user with that email address does not exist",
        pl: "Użytkownik z tym adresem e-mail nie istnieje",
      },
      passwordWrong: {
        ru: "Не верный пароль",
        en: "Invalid password",
        pl: "Nieprawidłowe hasło",
      },
    },
    inputEmailLabel: {
      ru: "Электронная почта",
      en: "E-mail",
      pl: "Adres e-mail",
    },
    inputPasswordLabel: {
      ru: "Пароль",
      en: "Password",
      pl: "Hasło",
    },
    buttons: {
      enter: {
        ru: "Войти",
        en: "Sign in",
        pl: "Zaloguj się",
      },
      register: {
        ru: "Зарегистрироваться",
        en: "Sign up",
        pl: "Zarejestruj się",
      },
    },
  },

  Navigation: {
    auth: {
      ru: "Авторизация",
      en: "Authorization",
      pl: "Autoryzacja",
    },
    contacts: {
      ru: "Контакты",
      en: "Contacts",
      pl: "Kontakt",
    },
    main: {
      ru: "Главная",
      en: "Main",
      pl: "Głowna",
    },
    planning: {
      ru: "Планирование",
      en: "Planning",
      pl: "Planowanie",
    },
    awards: {
      ru: "Награды",
      en: "Awards",
      pl: "Nagrody",
    },
  },

  Footer: {
    text: {
      ru: "Делаем жизнь родителей и детей легкой :)",
      en: "We make the life of parents and children easy :)",
      pl: "Sprawiamy, że życie rodziców i dzieci jest łatwiejsze :)",
    },
  },

  ModalLogout: {
    question: {
      ru: "Вы действительно хотите выйти?",
      en: "Are you sure you want to log out?",
      pl: "Czy na pewno chcesz się wylogować?",
    },
    yes: {
      ru: "Да",
      en: "Yes",
      pl: "Tak",
    },
    no: {
      ru: "Нет",
      en: "No",
      pl: "Nie",
    },
  },

  ContactsPage: {
    mainTittle: {
      ru: "Наша команда",
      en: "Our team",
      pl: "Nasz zespół",
    },
    subTitle: {
      ru: "Всегда готовы к новым вызовам!",
      en: "Always ready for new challenges!",
      pl: "Zawsze gotowy na nowe wyzwania!",
    },
  },

  MainPage: {
    days: [
      {
        name: {
          ru: "Понедельник",
          en: "Monday",
          pl: "Poniedziałek",
        },
        shortName: {
          ru: "Пн",
          en: "Mon",
          pl: "Pn",
        },
        url: "monday",
        id: 1,
      },
      {
        name: {
          ru: "Вторник",
          en: "Tuesday",
          pl: "Wtorek",
        },
        shortName: {
          ru: "Вт",
          en: "Tue",
          pl: "Wt",
        },
        url: "tuesday",
        id: 2,
      },
      {
        name: {
          ru: "Среда",
          en: "Wednesday",
          pl: "Środa",
        },
        shortName: {
          ru: "Ср",
          en: "Wed",
          pl: "Śr",
        },
        url: "wednesday",
        id: 3,
      },
      {
        name: {
          ru: "Четверг",
          en: "Thursday",
          pl: "Сzwartek",
        },
        shortName: {
          ru: "Чт",
          en: "Thu",
          pl: "Cz",
        },
        url: "thursday",
        id: 4,
      },
      {
        name: {
          ru: "Пятница",
          en: "Friday",
          pl: "Piątek",
        },
        shortName: {
          ru: "Пт",
          en: "Fri",
          pl: "Pt",
        },
        url: "friday",
        id: 5,
      },
      {
        name: {
          ru: "Суббота",
          en: "Saturday",
          pl: "Sobota",
        },
        shortName: {
          ru: "Сб",
          en: "Sat",
          pl: "Sb",
        },
        url: "saturday",
        id: 6,
      },
      {
        name: {
          ru: "Воскресенье",
          en: "Sunday",
          pl: "Niedziela",
        },
        shortName: {
          ru: "Вс",
          en: "Sun",
          pl: "Nd",
        },
        url: "sunday",
        id: 7,
      },
    ],
  },

  CurrentWeekRange: {
    monthes: [
      {
        ru: "Января",
        en: "January",
        pl: "Styczeń",
      },
      {
        ru: "Февраля",
        en: "February",
        pl: "Luty",
      },
      {
        ru: "Марта",
        en: "Marsz",
        pl: "March",
      },
      {
        ru: "Апреля",
        en: "April",
        pl: "Kwiecień",
      },
      {
        ru: "Мая",
        en: "May",
        pl: "Może",
      },
      {
        ru: "Июня",
        en: "June",
        pl: "Сzerwiec",
      },
      {
        ru: "Июля",
        en: "July",
        pl: "Lipiec",
      },
      {
        ru: "Августа",
        en: "August",
        pl: "Sierpień",
      },
      {
        ru: "Сентября",
        en: "September",
        pl: "Wrzesień",
      },
      {
        ru: "Октября",
        en: "October",
        pl: "Październik",
      },
      {
        ru: "Ноября",
        en: "November",
        pl: "Listopad",
      },
      {
        ru: "Декабря",
        en: "December",
        pl: "Grudzień",
      },
    ],

    textWeek: {
      ru: "Неделя:",
      en: "A week:",
      pl: "Tydzień:",
    },
  },

  WeekTabsContent: {
    tasksTitles: [
      { ru: "Застелить кровать", en: "Make the bed", pl: "Pościelić łóżko" },
      { ru: "Пропылесосить", en: "Vacuum", pl: "Odkurzyć" },
      { ru: "Полить цветы", en: "To water flowers", pl: "Podlać kwiaty" },
      { ru: "Почитать книгу", en: "Read a book", pl: "Poczytać książkę" },
      {
        ru: "Выкинуть мусор",
        en: "Throw out the trash",
        pl: "Wyrzucić śmieci",
      },
      { ru: "Почистить зубы", en: "Brush your teeth", pl: "Umyć zęby" },
      { ru: "Подмести", en: "Sweep", pl: "Zamieść" },
      { ru: "Собрать игрушки", en: "Collect toys", pl: "Posprzątać zabawki" },
    ],
  },

  ProgressBar: {
    pointsForAllPeriod: {
      ru: "Баланс баллов:",
      en: "Points balance:",
      pl: "Balans punktów:",
    },
    earnedThisWeekPoints: {
      ru: "Заработано баллов за эту неделю:",
      en: "Points earned this week:",
      pl: "Punkty otrzymane w tym tygodniu:",
    },
    plannedThisWeekPoints: {
      ru: "Запланировано баллов на эту неделю:",
      en: "Points planned for this week:",
      pl: "Punkty zaplanowane na ten tydzień:",
    },
  },

  CurrentDay: {
    myTasks: {
      ru: "Мoи задачи",
      en: "My tasks",
      pl: "Moje zadania:",
    },
  },

  CardList: {
    noTasksForToday: {
      ru: "На сегодняшний день задач нет",
      en: "There are no tasks for today",
      pl: "Na ten dzień nie ma zadań",
    },

    sheduleNewTasks: {
      ru: "Запланировать новые задачи",
      en: "Schedule new tasks",
      pl: "Zaplanuj zadania",
    },
  },

  CardPointAmount: {
    score: {
      ru: "балл",
      en: "point",
      pl: "punkty",
    },
    point: {
      ru: "балла",
      en: "point",
      pl: "punkty",
    },
    points: {
      ru: "баллов",
      en: "points",
      pl: "punktów",
    },
  },

  CardDaysList: {
    daysArray: [
      {
        day: {
          ru: "Пн",
          en: "Mon",
          pl: "Pn",
        },
        name: "monday",
      },
      {
        day: {
          ru: "Вт",
          en: "Tue",
          pl: "Wt",
        },
        name: "tuesday",
      },
      {
        day: {
          ru: "Ср",
          en: "Wed",
          pl: "Śr",
        },
        name: "wednesday",
      },
      {
        day: {
          ru: "Чт",
          en: "Thursday",
          pl: "Сzwartek",
        },
        name: "thursday",
      },
      {
        day: {
          ru: "Пт",
          en: "Fri",
          pl: "Pt",
        },
        name: "friday",
      },
      {
        day: {
          ru: "Сб",
          en: "Sat",
          pl: "Sb",
        },
        name: "saturday",
      },
      {
        day: {
          ru: "Вс",
          en: "Sun",
          pl: "Nd",
        },
        name: "sunday",
      },
    ],
    youCannotScheduleTaskInPastDay: {
      ru: "Нельзя запланировать задачу в прошлом дне",
      en: "You cannot schedule a task in the past day",
      pl: "Nie można zaplanować zadania w minionym dniu",
    },
  },

  CardFooter: {
    pointsAreNotEnough: {
      ru: "Баллов недостаточно",
      en: "Points are not enough",
      pl: "Punkty to za mało"
    },
    youHaveScheduledTask: {
      ru: "Вы запланировали задание!",
      en: "You have scheduled a task!",
      pl: "Zaplanowałeś zadanie!"
    },
    youHaveRemovedRaskFromScheduled: {
      ru: "Вы убрали задание из запланированных!",
      en: "You have removed the task from the scheduled ones!",
      pl: "Usunąłeś zadanie z zaplanowanych!",
    },
    youCannotRemoveTaskFromScheduled: {
      ru: "Вы не можете удалить задание из запланированных, потому что оно уже выполнено",
      en: "You cannot remove a task from the scheduled ones because it has already been completed",
      pl: "Nie możesz usunąć zadania z zaplanowanych, ponieważ zostało już wykonane"
    }
  },

  PlanningPoints: {
    defineTasksFor: {
      ru: "Определено задач на",
      en: "Defined tasks for",
      pl: "Zadania za"
    },
    points: {
      ru: "баллов",
      en: "points",
      pl: "punktów",
    }
  },

  CurrentWeekPlaning: {
    planOnWeek: {
      ru: "План на неделю:",
      en: "Weekly plan:",
      pl: "Tygodniowy plan:",
    }
  },
   
  AddCustomTask: {
    text: {
      ru: "Хочешь получить больше призов - добавь задачи ",
      en: "If you want to get more prizes - add tasks ",
      pl: "Chcesz otrzymać więcej nagród - dodaj zadania "
    }
  },

  NewTaskModal: {
    addTaskTextPlaceholder: {
      ru: "Добавить задание...",
      en: "Add task...",
      pl: "Dodaj zadanie..."
    },
    addScoreTextPlaceholder: {
      ru: "Добавить баллы...",
      en: "Add points...",
      pl: "Dodaj punkty..."
    },
    buttonCreateTask: {
      ru: "Создать задание",
      en: "Create task",
      pl: "Utwórz zadanie"
    },

    titleRequired: {
      ru: "Это обязательное поле",
      en: "This is a required field",
      pl: "To pole jest wymagane"
    },
    titleMax: {
      ru: "Не более 20 символов",
      en: "Maximum 20 characters",
      pl: "Maksymalnie 20 znaków"
    },
    taskPointsRange: {
      ru: "Значение должно быть от 1 до 50 баллов",
      en: "The value must be between 1 and 50 points",
      pl: "Wartość musi wynosić od 1 do 50 punktów"
    },
    inputFileText:{
      ru: "Перетащите сюда файл или щелкните, чтобы выбрать файл",
      en: "Drag 'n' drop file here or click to select file",
      pl: "Przeciągnij i upuść plik tutaj lub kliknij, aby wybrać plik",
    },
    fileFormat: {
      ru: "Файл должен быть в одном из форматов: jpg, jpeg, png, svg",
      en: "The file must be in one of the formats: jpg, jpeg, png, svg",
      pl: "Plik musi być w jednym z formatów: jpg, jpeg, png, svg"
    },
    fileSize: {
      ru: "Размер файла должен быть не более чем 1 мегабайт",
      en: "The file size must be no more than 1 megabyte",
      pl: "Rozmiar pliku nie może przekraczać 1 megabajt"
    },
    labelInputFile: {
      ru: "Загрузите изображение для вашего задания, размером не более 1 мегабайта (поле не обязательное к заполнению):",
      en: "Upload an image for your task, no more than 1 megabyte (this field is optional):",
      pl: "Prześlij obraz do swojego zadania, nie więcej niż 1 megabajt (to pole jest opcjonalne):"
    }
  },

  AwardsTitle: {
    myPrizes: {
      ru: "Мои призы",
      en: "My prizes",
      pl: "Moje nagrody",
    }
  },

  ModalCongrats: {
    title: {
      ru: "Поздравляем! Ты получаешь:",
      en: "Congratulations! You get:",
      pl: "Gratulujemy! Otrzymujesz:"
    },
    points: {
      ru: "баллов",
      en: "points",
      pl: "punktów",
    },
    sumPoints: {
      ru: "Суммарное количество баллов: ",
      en: "Total points: ",
      pl: "Suma punktów: ",
    },
    buttonConfirm: {
      ru: "Подтвердить",
      en: "Confirm",
      pl: "Potwierdzać",
    }
  },

  AwardsPage: {
    btnText: {
      ru: "Подтвердить",
      en: "Confirm",
      pl: "Potwierdzać",
    }
  }
};

export default localization;
