import React from "react";
import { useSelector } from "react-redux";
import s from "./MobilePlanningPoints.module.css";
import * as weekSelectors from "../../redux/week/weekSelectors";
import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";

const MobilePlanningPoints = () => {
  const { data } = useData();
  const language = data.language;

  const countPoints = useSelector((store) =>
    weekSelectors.getRewardsPlanned(store)
  );

  return (
    <p className={s.pointsText}>
      <span className={s.pointsAmount}>{countPoints}</span>
      {localization.PlanningPoints.points[language]}
    </p>
  );
};

export default MobilePlanningPoints;
