import React from "react";
import { NavLink } from "react-router-dom";
import CurrentWeekRange from "../CurrentWeekRange/CurrentWeekRange";
import s from "./WeekTabsTablet.module.css";
import { useData } from "../../context/DataContext";

const WeekTabsTablet = ({ days }) => {
  const { data } = useData();
  const windowInnerWidth = data.windowInnerWidth;

  return (
    <div className={s.weekDays}>
      {windowInnerWidth >= 768 && windowInnerWidth < 1280 && <CurrentWeekRange />}
      <ul className={s.weekDaysList}>
        {days.map((el) => (
          <li key={`${el.id}`} className={s.weekDaysListItem}>
            <NavLink
              exact
              isActive={(match, location) => {
                if (location.search === `?day=${el.url}`) {
                  return true;
                }
                return false;
              }}
              activeClassName={s.active}
              className={s.weekDaysListLink}
              to={(location) => `${location.pathname}?day=${el.url}`}
            >
              {el.shortName}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default WeekTabsTablet;
