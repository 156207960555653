import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import ProgressBar from "../ProgressBar/ProgressBar";
import CardList from "../CardsList/CardsList";
import CurrentWeekRange from "../CurrentWeekRange/CurrentWeekRange";
import CurrentDay from "../CurrentDay/CurrentDay";

import s from "./WeekTabsContent.module.css";
import * as tasksSelectors from "../../redux/tasks/tasksSelectors";

import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";

const WeekTabsContent = () => {
  const { data } = useData();
  const windowInnerWidth = data.windowInnerWidth;
  const language = data.language;

  let tasks = useSelector((store) => tasksSelectors.getTasks(store));

  // change tasks titles to selected application language - start:
  const tasksTitlesArray = localization.WeekTabsContent.tasksTitles.map(
    (el) => el[language]
  );
  tasks = tasks.map((item, idx) => ({
    ...item,
    title: tasksTitlesArray[idx] ? tasksTitlesArray[idx] : item.title,
  }));
  // change tasks titles to selected application language - end.

  // determination of the name of the day and the exact date - start:
  let dayName = null;
  let date = null;
  const { search } = useLocation();
  const urlDay = new URLSearchParams(search).get("day");
  const daysNamesArray = localization.MainPage.days.map((el) => ({
      ...el, name: el.name[language], shortName: el.shortName[language]
  }));

  if (urlDay) {
    dayName = daysNamesArray.find(el => el.url === urlDay).name;
    const currentDayIndex = daysNamesArray.reduce((acc, el, ind) => {
      if(el.url === urlDay) {
        return acc = ind;
      } else {
        return acc;
      }
    }, 0);
    const currentDayObject = tasks[0].days[currentDayIndex];
    date = moment(currentDayObject.date).format("DD.MM.YYYY");
  }
  // determination of the name of the day and the exact date - end.

  return (
    <div className={s.container}>
      {(windowInnerWidth < 768 || windowInnerWidth >= 1280) && (
        <CurrentWeekRange />
      )}

      <div className={s.progressBarAndCurrentDayWrapper}>
        {windowInnerWidth >= 768 && <ProgressBar />}
        {dayName && date && <CurrentDay day={dayName} date={date} />}
      </div>

      <div className={s.cardWrapper}>
        {tasks && <CardList tasks={tasks} />}
        {windowInnerWidth < 768 && <ProgressBar />}
      </div>
    </div>
  );
};

export default WeekTabsContent;
