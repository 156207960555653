import React from "react";
import * as moment from "moment";
import "moment/locale/uk";
import { useSelector } from "react-redux";
import s from "./CurrentWeekPlaning.module.css";

import * as weekSelectors from "../../redux/week/weekSelectors";
import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";

const CurrentWeekPlaning = () => {
  const { data } = useData();
  const language = data.language;

  const startWeekDate = useSelector((store) =>
    weekSelectors.getStartWeekDate(store)
  );
  const endWeekDate = useSelector((store) =>
    weekSelectors.getEndWeekDate(store)
  );

  const startOfWeek = moment(startWeekDate).format("DD");
  const endOfWeek = moment(endWeekDate).format("DD.MM.YYYY");

  return (
    <>
      <p className={s.wrapper}>
        {localization.CurrentWeekPlaning.planOnWeek[language]}
        <span className={s.week}>
          {startOfWeek} - {endOfWeek}
        </span>
      </p>
    </>
  );
};

export default CurrentWeekPlaning;
