import AuthPage from '../pages/AuthPage/AuthPage';
import MainPage from '../pages/MainPage/MainPage';
import PlanningPage from '../pages/PlanningPage/PlanningPage';
import AwardsPage from '../pages/AwardsPage/AwardsPage';
// import ContactsPage from '../pages/ContactsPage/ContactsPage';

const routes = [
  // {
  //   path: '/contact-us',
  //   component: ContactsPage,
  //   protected: false,
  //   name: 'ContactsPage',
  // },
  { path: '/auth', component: AuthPage, protected: false, name: 'AuthPage' },
  {
    path: '/planning',
    component: PlanningPage,
    protected: true,
    name: 'PlanningPage',
  },
  {
    path: '/awards',
    component: AwardsPage,
    protected: true,
    name: 'AwardsPage',
  },
  { path: '/', component: MainPage, protected: true, name: 'MainPage' },
];

export default routes;
