import * as giftActions from "./giftActions";
import * as API from "../../services/api";
import { toast } from "react-toastify";

export const getGiftOperation = (language) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { token } = state.auth;

  dispatch(giftActions.startGetGiftAction());

  API.getGift(token, language)
    .then((res) => {
      if (res.data.success) {
        dispatch(giftActions.successGetGiftAction(res.data.ruGifts || res.data.enGifts || res.data.plGifts));
      }
    })
    .catch((error) => {
      if (error.response) {
        const status = error.response.status;
        if (status === 401) {
          toast.error("Unauthorized user");
        } else {
          dispatch(giftActions.errorGetGiftAction(error.response.data));
        }
      } else {
        dispatch(giftActions.errorGetGiftAction(error));
      }
    });
};

export const receiveGiftsOperation = () => (dispatch, getState) => {
  dispatch(giftActions.startReceiveGiftsAction());
  const state = getState();
  const { token } = state.auth;
  const { giftArray } = state.gifts;

  const dataToRequest = {giftIds: []};
  giftArray.forEach((el) => {
    if(el.isSelected) {
      return dataToRequest.giftIds.push(el.id)
    } else {
      return null;
    }
  });

  API.patchReceiveGifts(token, dataToRequest)
    .then((res) => {
      dispatch(giftActions.successReceiveGiftsAction(res.data));
    })
    .catch((error) => {
      if (error.response) {
        const status = error.response.status;
        if (status === 400) {
            toast.error("Bad request (invalid request body or id) / No token provided");
        } else if (status === 401) {
            toast.error("Unauthorized (invalid token)");
        } else if (status === 404) {
            toast.error("Invalid user / Invalid session / Task not found / Day not found");
        } else if (status === 409) {
          toast.error("Not enough rewards");
      } 
        dispatch(giftActions.errorReceiveGiftsAction(error.response.data));
      } else {
        dispatch(giftActions.errorReceiveGiftsAction(error));
      }
    });
};