import types from '../types';

// register
export const startRegisterAction = () => ({
  type: types.START_REGISTER,
});
export const successRegisterAction = userData => ({
  type: types.SUCCESS_REGISTER,
  payload: {
    userData,
  },
});
export const errorRegisterAction = err => ({
  type: types.ERROR_REGISTER,
  payload: {
    error: err,
  },
});

// login
export const startLoginAction = () => ({
  type: types.START_LOGIN,
});
export const successLoginAction = userData => ({
  type: types.SUCCESS_LOGIN,
  payload: {
    userData,
  },
});
export const errorLoginAction = err => ({
  type: types.ERROR_LOGIN,
  payload: {
    error: err,
  },
});

// logout
export const startLogoutAction = () => ({
  type: types.START_LOGOUT,
});
export const successLogoutAction = () => ({
  type: types.SUCCESS_LOGOUT,
});
export const errorLogoutAction = err => ({
  type: types.ERROR_LOGOUT,
  payload: {
    error: err,
  },
});

// getUser
export const startGetUserAction = () => ({
  type: types.START_GET_USER,
});
export const successGetUserAction = userData => ({
  type: types.SUCCESS_GET_USER,
  payload: {
    userData,
  },
});
export const errorGetUserAction = err => ({
  type: types.ERROR_GET_USER,
  payload: {
    error: err,
  },
});

// set token in store
export const setTokenInStoreAction = token => ({
  type: types.SET_TOKEN_IN_STORE,
  payload: {
    token,
  },
});
