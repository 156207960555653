import React from 'react';
import s from './CardBody.module.css';

const CardBody = ({ imageUrl, title, addClass = '' }) => (
  <img
    className={`${s.CardBody} ${addClass}`}
    src={imageUrl}
    alt={title}
    width="280"
    height="192"
  />
);

export default CardBody;
