export const getIsAuth = store => store.auth.isAuth;

export const getToken = store => store.auth.token;

export const getIsAuthLoading = store => store.auth.isLoading;

export const getIsAuthErrorMessage = store => store.auth.error.message;

export const getUser = store => store.auth.user;

export const getBalance = store => store.auth.user.balance;