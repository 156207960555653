import React from 'react';
import LoaderLibrary from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styles from './Loader.module.css';

const Loader = () => {
  return (
    <div className={styles.loader}>
      <LoaderLibrary type="Oval" color="rgb(239, 187, 67)" height={80} width={80} />
    </div>
  );
};

export default Loader;
