import React from "react";
import { useSelector } from "react-redux";
import * as weekSelectors from '../../redux/week/weekSelectors';
import * as moment from "moment";
import s from "./CurrentWeekRange.module.css";

import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";

const CurrentWeekRange = () => {
  const { data } = useData();
  const language = data.language;

  const startWeekDate = useSelector(store => weekSelectors.getStartWeekDate(store));
  const endWeekDate = useSelector(store => weekSelectors.getEndWeekDate(store));

  const startOfWeek = moment(startWeekDate).format("DD");
  const endOfWeek = moment(endWeekDate).format("DD");

  // функция получения месяца, из того что в сторе
  function getThisMonth() {
    let monthes = localization.CurrentWeekRange.monthes.map(
      (el) => el[language]
    );

    return monthes[moment().get("M")];
  }
  const month = getThisMonth(moment().get("M"));

  return (
    <div className={s.wrapper}>
      <p className={s.week}>
        {localization.CurrentWeekRange.textWeek[language]} {Number(startOfWeek)}-{Number(endOfWeek)} {month}
      </p>
    </div>
  );
};

export default CurrentWeekRange;
