import React from "react";
import CardBody from "../CardBody/CardBody";
import catTop from "../../assets/images/Modal/catTransparent.png";
import ModalBackdropWrapper from "../ModalBackdropWrapper/ModalBackdropWrapper";
// import AwardsSubmitButton from "../AwardsSubmitButton/AwardsSubmitButton";
import { ReactComponent as Cancel } from "../../assets/icons/close/cancel.svg";
import s from "./ModalCongrats.module.css";

import localization from "../../utils/localization";
import { useData } from "../../context/DataContext";
import { useSelector } from "react-redux";
import * as giftSelectors from '../../redux/gift/giftSelectors';

const ModalCongrats = ({ onClose }) => {
  const { data } = useData();
  const language = data.language;

  const purchasedGiftIds = useSelector(giftSelectors.getPurchasedGiftIds);
  const awards = useSelector(giftSelectors.getGifts);
  
  const filteredAwards = [];
  awards.forEach((el) => {
    if(purchasedGiftIds.includes(el.id)) {
      return filteredAwards.push(el)
    } else {
      return null;
    }
  });

  return (
    <>
      <ModalBackdropWrapper onClose={onClose}>
        <div className={s.content}>
          <button type="button" className={s.taskCloseButton}>
            <Cancel onClick={onClose} />
          </button>

          <img className={s.catTop} src={catTop} alt="cat" />
          <h2 className={s.modalTitleText}>
            {localization.ModalCongrats.title[language]}
          </h2>

          <ul className={s.prizeContainerList}>
            {filteredAwards.map((el) => (
              <li key={el.id} className={s.prizeContainerItem}>
                <CardBody
                  imageUrl={el.imageUrl}
                  title={el.title}
                  addClass={s.imagePrize}
                />

                <p className={s.prizeContainerItemText}>
                  {el.title}
                </p>
              </li>
            ))}
          </ul>

        </div>
      </ModalBackdropWrapper>
    </>
  );
};

export default ModalCongrats;
