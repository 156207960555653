import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth/authReducer';
import tasksReducer from './tasks/tasksReducer';
import weekReducer from './week/weekReducer';
import globalReducer from './global/globalReducer';
import giftReducer from './gift/giftReducer';

const authPersistConfig = {
  key: 'sessionEnKidslike',
  storage,
  whitelist: ['token'],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  tasks: tasksReducer,
  week: weekReducer,
  global: globalReducer,
  gifts: giftReducer,
});

export default rootReducer;
