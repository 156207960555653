import { combineReducers } from "redux";
import types from "../types";

// ----------------Loader----------------------------------------
const loaderReducer = (state = false, action) => {
  switch (action.type) {
    case types.START_GET_GIFT:
    case types.START_PATCH_ALL_TASKS_DAYS:
    case types.START_PATCH_SINGLE_TASK_DAYS:
    case types.START_SWITCH_TASK_DAY:
    case types.START_CREATE_TASK:
    case types.START_RECEIVE_GIFTS:
      return true;

    case types.SUCCESS_GET_GIFT:
    case types.ERROR_GET_GIFT:
    case types.SUCCESS_PATCH_ALL_TASKS_DAYS:
    case types.ERROR_PATCH_ALL_TASKS_DAYS:
    case types.SUCCESS_PATCH_SINGLE_TASK_DAYS:
    case types.ERROR_PATCH_SINGLE_TASK_DAYS:
    case types.SUCCESS_SWITCH_TASK_DAY:
    case types.ERROR_SWITCH_TASK_DAY:
    case types.SUCCESS_CREATE_TASK:
    case types.ERROR_CREATE_TASK:
    case types.SUCCESS_RECEIVE_GIFTS:
    case types.ERROR_RECEIVE_GIFTS:
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  isLoading: loaderReducer,
});
