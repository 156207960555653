import React from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { throttle } from "throttle-debounce";
import s from "./CardFooter.module.css";
import CardPointAmount from "../CardPointAmount/CardPointAmount";
import CardTitle from "../CardTitle/CardTitle";
import CardSelectDays from "../CardSelectDays/CardSelectDays";
import CardTaskToggle from "../CardTaskToggle/CardTaskToggle";
import CardTaskStatus from "../CardTaskStatus/CardTaskStatus";
import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";

import * as authSelectors from '../../redux/auth/authSelectors';
import * as giftActions from '../../redux/gift/giftActions';
import * as tasksActions from '../../redux/tasks/tasksActions';
import * as tasksOperation  from '../../redux/tasks/tasksOperation';
import * as tasksSelectors from "../../redux/tasks/tasksSelectors";

const today = moment().isoWeekday();
const momentObj = moment();

const CardFooter = ({ ...taskInfo }) => {
  const { data } = useData();
  const language = data.language;

  const { search, pathname } = useLocation();
  const { _id, title, reward, days, isCompleted, isSelected, date } = taskInfo;
  const taskPoints = reward || taskInfo.price;
  const isDone = isCompleted;
  const taskId = _id || taskInfo.id;
  
  const userPoints = useSelector((store) => authSelectors.getBalance(store));
  const dispatch = useDispatch();

  const handleChangeAwards = ({ target }) => {
    const value = target.checked ? taskPoints : 0 - taskPoints;
    if (userPoints - value < 0) {
      toast.error(localization.CardFooter.pointsAreNotEnough[language]);
    } else {
      dispatch(giftActions.toggleSelectedCardAction(taskId, taskPoints, isSelected));
    }
  };

  const handleChangeTaskToday = (id, date) => {
    dispatch(tasksOperation.patchSwitchTaskDayOperation(id, date));
  };

  const throttled = throttle(5000, (id, date) => {
    handleChangeTaskToday(id, date);
  });

  let tasks = useSelector((store) => tasksSelectors.getTasks(store));

  const handleChangePlanningTask = ({ target }) => {
    toast.dismiss();

    let isTaskCompleted = null;
    tasks.forEach(el => {
      if(target.id.includes(el._id)) {
        return el.days.forEach(item => {
          if(target.id.includes(item._id)) {
            isTaskCompleted = item.isCompleted;
          }
        })
      }
    });
    
    if(isTaskCompleted) {
      toast.error(localization.CardFooter.youCannotRemoveTaskFromScheduled[language]);
    } else {
      if(target.checked) {
        toast.success(localization.CardFooter.youHaveScheduledTask[language]);
      } else {
        toast.success(localization.CardFooter.youHaveRemovedRaskFromScheduled[language]);
      }
      dispatch(tasksActions.taskPlanningChangeAction(target.id));
    }
  };

  const renderElement = () => {
    let url;

    const urlDay = new URLSearchParams(search).get("day");

    if (urlDay) {
      url = momentObj.day(urlDay).isoWeekday();
    }

    if (pathname === "/planning") {
      return (
        <CardSelectDays id={taskId} days={days} onChange={handleChangePlanningTask} />
      );
    }

    if (pathname === "/awards") {
      return (
        <CardTaskToggle onChange={handleChangeAwards} id={taskId} value={isSelected} />
      );
    }
    if (today === url) {
      return (
        <CardTaskToggle
          id={`${taskId}_${date}`}
          onChange={() => throttled(taskId, date)}
          value={isDone}
        />
      );
    }
    if (url > today) {
      return null;
    }
    if (url < today) {
      return <CardTaskStatus mainValue={isDone} />;
    }
  };

  return (
    <div className={s.card_footer}>
      <div>
        <CardTitle title={title} />
        <CardPointAmount point={taskPoints} />
      </div>
      <>{renderElement()}</>
    </div>
  );
};

export default CardFooter;
