import React, { useRef, useEffect } from "react";
import s from "./ModalBackdropWrapper.module.css";

const ModalBackdropWrapper = ({ onClose, children }) => {
  const modalRef = useRef(null);

  const handleKeyPress = (e) => {
    if (e.code !== "Escape") {
      return;
    }
    onClose();
  };

  const handleBackdropClick = (e) => {
    if (modalRef.current && e.target !== modalRef.current) {
      return;
    }
    onClose();
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  return (
    <div
      className={s.modalWrapper}
      ref={modalRef}
      onClick={handleBackdropClick}
      role="presentation"
    >
        {children}
    </div>
  );
};

export default ModalBackdropWrapper;
