import React from "react";
import moment from "moment";
import shortid from "shortid";
import style from "./CardDaysList.module.css";
import { ReactComponent as Done } from "../../assets/icons/done/check-mark-black-outline.svg";
import { toast } from "react-toastify";
import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";

const today = moment().isoWeekday();

const CardDaysList = ({ id, days, onChange }) => {
  const { data } = useData();
  const language = data.language;

  const isDisabled = (currentDate) => {
    const currentDay = moment(currentDate).isoWeekday();
    return today > currentDay;
  };

  const errorToast = () => toast.error(localization.CardDaysList.youCannotScheduleTaskInPastDay[language]);

  const renderDays = days.map((day) => {
    const { _id: dayId } = day;
    const key = `${id}_${dayId}`;

    return (
      <li key={key}>
        {isDisabled(day.date) ? (
          <label className={style.container_checkbox_disable} htmlFor={`${key}`}>
            <input
              id={key}
              type="checkbox"
              className={style.input}
              name={day.name}
              checked={day.isActive}
              onChange={errorToast}
            />
            {day.title}
            <span className={style.checkmark}>
              <Done className={style.checkmark_icon} width="15px" height="15px" />
            </span>
          </label>
        ) : (
            <label className={style.container_checkbox} htmlFor={`${key}`}>
              <input
                id={key}
                type="checkbox"
                className={style.input}
                name={day.name}
                checked={day.isActive}
                onChange={onChange}
                disabled={isDisabled(day.date)}
              />
              {day.title}
              <span className={style.checkmark}>
                <Done className={style.checkmark_icon} width="15px" height="15px" />
              </span>
            </label>
          )}
      </li>
    );
  });

  return (
    <form className={style.form}>
      <ul className={style.DayList} id={shortid.generate()}>
        {renderDays}
      </ul>
    </form>
  );
};

export default CardDaysList;
