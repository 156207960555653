// helpers functions - start:
export function setPlanningTask(tasksInStore, taskId_dayId) {

    const result = tasksInStore.map((el) => {
        const { _id: taskId } = el;

        return taskId_dayId.includes(taskId) ?
            {
                ...el,
                days: el.days.map((elem) => {
                    return taskId_dayId.includes(elem._id)
                        ? { ...elem, isActive: !elem.isActive }
                        : { ...elem };
                }),
            }
            :
            { ...el };
    });
    return result;
};

export function setDoneTask(array, { data }) {
    const result = array.map((el) => {
        const { _id: id } = el;
        return data.includes(id)
            ? {
                ...el,
                days: el.days.map((elem) => {
                    return data.includes(elem.date)
                        ? { ...elem, isCompleted: !elem.isCompleted }
                        : { ...elem };
                }),
            }
            : { ...el };
    });
    return result;
}

export function fetchingTask(id, arr = []) {
    let putBody;
    if (arr) {
        arr.forEach((el) => (el._id === id ? (putBody = { days: el.days }) : null));
    }
    return putBody;
}

export function totalWeekPlanPoints(tasks) {
    return tasks.reduce((total, task) => {
        const { taskPoints } = task;
        const sumActive = task.days.reduce((amount, el) => {
            return el.isActive === true ? amount + 1 : amount;
        }, 0);
        return total + taskPoints * sumActive;
    }, 0);
}
// helpers functions - end.
