import types from "../types";
import * as helpers from '../../utils/helpers';

const initialState = [];

const tasks = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SUCCESS_REGISTER:
    case types.SUCCESS_LOGIN:
    case types.SUCCESS_GET_USER:
      return [...payload.userData.week.tasks];

    case types.ERROR_REGISTER:
    case types.ERROR_LOGIN:
    case types.SUCCESS_LOGOUT:
    case types.ERROR_GET_USER:
      return initialState;

    case types.SUCCESS_PATCH_ALL_TASKS_DAYS:
      const updatedTasks = payload.updatedTasks;
      const newState = state.map((el) => {
        const updatedTask = updatedTasks.find((item) => item.taskId === el._id);
        return {
          ...el,
          days: [...updatedTask.days],
        };
      });
      return [...newState];

    case types.TASKS_PLANNING_CHANGE:
      return helpers.setPlanningTask(state, payload.taskId_dayDateString);

    case types.SUCCESS_PATCH_SINGLE_TASK_DAYS:
    case types.SUCCESS_SWITCH_TASK_DAY:
      const updatedTask = payload.updatedTask;
      const newStateWithUpdatedTask = state.map((el) => {
        if (updatedTask.id === el._id) {
          return ({
            ...el,
            days: [...updatedTask.days],
          })
        } else {
          return ({
            ...el
          })
        }
      });
      return [...newStateWithUpdatedTask];

      case types.SUCCESS_CREATE_TASK:
        return [...state, {...payload}];

    default:
      return state;
  }
};

export default tasks;