import React from "react";
import { useSelector } from "react-redux";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import s from "./ProgressBar.module.css";

import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";
import * as authSelectors from "../../redux/auth/authSelectors";
import * as weekSelectors from "../../redux/week/weekSelectors";

const ProgressBar = () => {
  const { data } = useData();
  const language = data.language;

  const userPointsBalance = useSelector((store) =>
    authSelectors.getBalance(store)
  );
  const userPointsEarnedThisWeek = useSelector((store) =>
    weekSelectors.getRewardsGained(store)
  );
  const userPointsPlannedThisWeek = useSelector((store) =>
    weekSelectors.getRewardsPlanned(store)
  );

  let percent = 100;
  if (userPointsEarnedThisWeek) {
    percent = Number.parseFloat(
      (userPointsEarnedThisWeek / userPointsPlannedThisWeek) * 100
    ).toFixed(0);
  }
  if (userPointsEarnedThisWeek === 0) {
    percent = 0;
  };

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.info}>
          <p className={s.progressTitle}>
            {localization.ProgressBar.pointsForAllPeriod[language]}

            <span className={s.pointsTitle}>{userPointsBalance}</span>
          </p>

          <p className={s.progressText}>
            {localization.ProgressBar.earnedThisWeekPoints[language]}

            <span className={s.points}>{userPointsEarnedThisWeek}</span>
          </p>
          <p className={s.progressText}>
            {localization.ProgressBar.plannedThisWeekPoints[language]}

            <span className={s.points}>{userPointsPlannedThisWeek}</span>
          </p>
        </div>

        <div className={s.progressLibrary_container}>
          <span className={s.secondPoints}>
            <span>{userPointsEarnedThisWeek}</span>/{userPointsPlannedThisWeek}
          </span>
          <Progress percent={percent > 100 ? 100 : percent} />
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
