import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useLifecycles } from "react-use";
import routes from '../routes/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import * as authOperation from '../redux/auth/authOperation';
import Loader from './Loader/Loader';
import Header from './Header/Header';
import * as authSelectors from '../redux/auth/authSelectors';
import * as globaSelectors from '../redux/global/globalSelectors';
import { useData } from "../context/DataContext";
import lodashDebounce from "lodash.debounce";

const makeRoutes = () =>
  routes.map(route =>
    route.protected ? (
      <ProtectedRoute exact key={route.name} {...route} />
    ) : (
      <Route key={route.name} {...route} />
    ),
  );

const App = () => {
  const { setValues } = useData();
  const dispatch = useDispatch();

  const isAuthLoading = useSelector(store =>
    authSelectors.getIsAuthLoading(store),
  );
  const isGlobalLoading = useSelector(store =>
    globaSelectors.getIsGlobalLoading(store),
  );

  const updateDimensions = () => {
    setValues({windowInnerWidth: window.innerWidth})
  };

  useLifecycles(
    () => {
      const getUser = () => dispatch(authOperation.getUserOperation());
      getUser();

      window.addEventListener("resize", lodashDebounce(updateDimensions, 200));
      setValues({ windowInnerWidth: window.innerWidth });
    },
    () => {
      window.removeEventListener("resize", updateDimensions);
    }
  );

  return (
    <BrowserRouter basename="/">
        <Header />
        <Switch>
          {makeRoutes()}
          <Route path="*">
              <Redirect to="/" />
          </Route>
        </Switch>

      {isAuthLoading && <Loader />}
      {isGlobalLoading && <Loader />}

      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
