import React from 'react';
import Logo from '../Logo/Logo';
import localization from '../../utils/localization';
import { useData } from "../../context/DataContext";
import s from './FooterAuthPage.module.css';

const FooterAuthPage = () => {
    const { data } = useData();
    const language = data.language;

    return (
        <footer className={s.footer}>
            <Logo smallLogo={true} />
            <p className={s.text}>{localization.Footer.text[language]}</p>
            <p className={s.date}>2020</p>
        </footer>
    );
};

export default FooterAuthPage;