import React from "react";
import * as authSelectors from "../../redux/auth/authSelectors";
import { useSelector } from "react-redux";
import UserInfoDefaultLogo from "../../assets/images/UserInfoDefaultLogo.jpg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout/logoutMobile.svg";
import s from "./UserInfoMobile.module.css";

const changeUserName = (name) => {
  if (name.indexOf(" ") !== -1) {
    let shortName = name.slice(0, name.indexOf(" "));
    shortName = shortName.length > 10 ? shortName.slice(0, 10) : shortName;
    return shortName;
  }
  if (name.indexOf("@") !== -1) {
    let shortName = name.slice(0, name.indexOf("@"));
    shortName = shortName.length > 10 ? shortName.slice(0, 10) : shortName;
    return shortName;
  }
  return name.length > 10 ? name.slice(0, 10) : name;
};

const UserInfoMobile = ({ openModalLogout }) => {
  const user = useSelector((store) => authSelectors.getUser(store));
  const defaultName = user.email;

  return (
      <div className={s.container}>
        <div className={s.userDataContainer}>
          <img
            className={s.userInfoLogo}
            alt="userlogo"
            src={user.avatarUrl || UserInfoDefaultLogo}
          />
          <p className={s.userInfoName}>
            {changeUserName(user.name || defaultName)}
          </p>
        </div>

        <LogoutIcon className={s.logoutIcon} onClick={openModalLogout} />
      </div>
  );
};

export default UserInfoMobile;
