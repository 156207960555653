import React from "react";

const Burger = () => {
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <g data-name="Layer 2" id="Layer_2">
        <path d="M28,10H4A1,1,0,0,1,4,8H28a1,1,0,0,1,0,2Z" />
        <path d="M28,17H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
        <path d="M28,24H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
      </g>
    </svg>
  );
};

export default Burger;
