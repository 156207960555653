import types from '../types';

// get gifts
export const startGetGiftAction = () => ({
  type: types.START_GET_GIFT,
});
export const successGetGiftAction = giftArray => ({
  type: types.SUCCESS_GET_GIFT,
  payload: {
    giftArray,
  },
});
export const errorGetGiftAction = err => ({
  type: types.ERROR_GET_GIFT,
  payload: {
    error: err,
  },
});

export const toggleSelectedCardAction = (id, points, isSelected) => ({
  type: types.GIFT_CHANGES_TOGGLE_SELECTED,
  payload: {
    id,
    points,
    isSelected,
  },
});

// receive gifts
export const startReceiveGiftsAction = () => ({
  type: types.START_RECEIVE_GIFTS,
});
export const successReceiveGiftsAction = data => ({
  type: types.SUCCESS_RECEIVE_GIFTS,
  payload: {
    data,
  },
});
export const errorReceiveGiftsAction = err => ({
  type: types.ERROR_RECEIVE_GIFTS,
  payload: {
    error: err,
  },
});

export const clearPurchasedGiftIds = () => ({
  type: types.CLEAR_PURCHASED_GIFT_IDS,
});