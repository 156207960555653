import React, { useState } from "react";
import s from "./MobileAddCustomTask.module.css";
import NewTaskModal from "../NewTaskModal/NewTaskModal";

const MobileAddCustomTask = () => {
  const [isShowNewTaskModal, setIsShowNewTaskModal] = useState(false);
  const openNewTaskModal = () => {
    setIsShowNewTaskModal(true);
  };
  const closeNewTaskModal = () => {
    setIsShowNewTaskModal(false);
  };

  return (
    <>
      <button className={s.addTaskBtn} type="button" onClick={openNewTaskModal}>
        &#43;
      </button>

      {isShowNewTaskModal && <NewTaskModal onClose={closeNewTaskModal} />}
    </>
  );
};

export default MobileAddCustomTask;
