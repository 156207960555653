import React from 'react';
import style from './AwardsSubmitButton.module.css';

const AwardsSubmitButton = ({ onClick, buttonText = "OK" }) => {
  return (
    <div className={style.present_button}>
      <button onClick={onClick} className={style.button} type="submit">
        {buttonText}
      </button>
    </div>
  );
};

AwardsSubmitButton.defaultProps = {
  buttonText: 'OK',
};

export default AwardsSubmitButton;
