import React, { useState } from "react";
import s from "./AddCustomTask.module.css";
import NewTaskModal from "../NewTaskModal/NewTaskModal";

import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";

const AddCustomTask = () => {
  const { data } = useData();
  const language = data.language;

  const [isShowNewTaskModal, setIsShowNewTaskModal] = useState(false);
  const openNewTaskModal = () => {
    setIsShowNewTaskModal(true);
  };
  const closeNewTaskModal = () => {
    setIsShowNewTaskModal(false);
  };

  return (
    <>
      <div className={s.customWrapper}>
        <p className={s.hiddenText}>
          {localization.AddCustomTask.text[language]} 
          <span role="img" aria-label="SMILING FACE WITH SMILING EYES">
            &#128522;
          </span>
        </p>

        <button className={s.addTaskBtn} type="button" onClick={openNewTaskModal}>
          <span className={s.iconPlus}>&#43;</span>
        </button>
      </div>
      {isShowNewTaskModal && <NewTaskModal onClose={closeNewTaskModal} />}
    </>
  );
};

export default AddCustomTask;
