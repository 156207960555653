import React from "react";
import s from "./CurrentDay.module.css";
import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";

const CurrentDay = ({ day, date }) => {
  const { data } = useData();
  const language = data.language;

  return (
    <div className={s.wrapper}>
      <h2 className={s.task}>{localization.CurrentDay.myTasks[language]}</h2>
      <p className={s.day}>
        {day}, {date}
      </p>
    </div>
  );
};

export default CurrentDay;
