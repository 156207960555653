import React, { useRef, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "../../routes/routes";
import * as authSelectors from "../../redux/auth/authSelectors";
import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";
import UserInfoMobile from "../UserInfoMobile/UserInfoMobile";
import s from "./HeaderNavigationModal.module.css";

const routesObject = routes.reduce(
  (acc, el) => ({ ...acc, [el.name]: el.path }),
  {}
);

const HeaderNavigationModal = ({ onClose, openModalLogout }) => {
  const { data } = useData();
  const language = data.language;

  const isAuth = useSelector((store) => authSelectors.getIsAuth(store));

  const wrapperRef = useRef();

  const handleBackdropClick = (e) => {
    if (wrapperRef.current && e.target !== wrapperRef.current) {
      return;
    }
    onClose();
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (e.code !== "Escape") {
        return;
      }
      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    document.querySelector("body").style.overflow = "hidden";
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      document.querySelector("body").style.overflow = "visible";
    };
  }, [handleKeyPress]);

  return (
    <div onClick={handleBackdropClick} className={s.wrapper} ref={wrapperRef}>
      <div className={s.content}>
        <ul className={s.navigationList}>
          <button className={s.close} onClick={onClose} type="button" />

          {!isAuth && (
            <li className={s.navigationItem}>
              <NavLink
                exact
                className={s.navigationLink}
                activeClassName={s.activeNavigationLink}
                to={routesObject.AuthPage}
                onClick={onClose}
              >
                {localization.Navigation.auth[language]}
              </NavLink>
            </li>
          )}
          {isAuth && (
            <>
              <li className={s.navigationItem}>
                <NavLink
                  exact
                  className={s.navigationLink}
                  activeClassName={s.activeNavigationLink}
                  to={routesObject.MainPage}
                  onClick={onClose}
                >
                  {localization.Navigation.main[language]}
                </NavLink>
              </li>
              <li className={s.navigationItem}>
                <NavLink
                  exact
                  className={s.navigationLink}
                  activeClassName={s.activeNavigationLink}
                  to={routesObject.PlanningPage}
                  onClick={onClose}
                >
                  {localization.Navigation.planning[language]}
                </NavLink>
              </li>
              <li className={s.navigationItem}>
                <NavLink
                  exact
                  className={s.navigationLink}
                  activeClassName={s.activeNavigationLink}
                  to={routesObject.AwardsPage}
                  onClick={onClose}
                >
                  {localization.Navigation.awards[language]}
                </NavLink>
              </li>
            </>
          )}
          {/* <li className={s.navigationItem}>
            <NavLink
              exact
              className={s.navigationLink}
              activeClassName={s.activeNavigationLink}
              to={routesObject.ContactsPage}
              onClick={onClose}
            >
              {localization.Navigation.contacts[language]}
            </NavLink>
          </li> */}
        </ul>

        {isAuth && (
          <UserInfoMobile
            openModalLogout={() => {
              openModalLogout();
              onClose();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderNavigationModal;
