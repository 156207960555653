import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./AwardsPage.module.css";
import AwardsTitle from "../../components/AwardsTitle/AwardsTitle";
import CardsList from "../../components/CardsList/CardsList";
import AwardsSubmitButton from "../../components/AwardsSubmitButton/AwardsSubmitButton";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import ModalCongrats from "../../components/ModalCongrats/ModalCongrats";
import Footer from '../../components/Footer/Footer';

import { getGiftOperation, receiveGiftsOperation } from "../../redux/gift/giftOperation";
import { getGifts, getTotalPoints } from "../../redux/gift/giftSelectors";
import { clearPurchasedGiftIds } from '../../redux/gift/giftActions';

import { useData } from "../../context/DataContext";
import localization from '../../utils/localization';

const AwardsPage = () => {
  const { data } = useData();
  const language = data.language;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGiftOperation(language));
  }, [dispatch, language]);

  const awards = useSelector(getGifts);
  const totalAwardsPoints = useSelector(getTotalPoints);

  const [isShowModalCongrats, setIsShowModalCongrats] = useState(false);

  const submitReceiveGifts = () => {
    dispatch(receiveGiftsOperation());
    setIsShowModalCongrats(true);
  };

  const onCloseModalCongrats = () => {
    dispatch(clearPurchasedGiftIds());
    setIsShowModalCongrats(false);
  };

  return (
    <div className={s.wrapper_awards}>
      {isShowModalCongrats && (
        <ModalCongrats
          onClose={onCloseModalCongrats}
        />
      )}
      <div className={s.present_cards}>
        <div className={s.present_items}>
          <AwardsTitle />
          <ProgressBar />
        </div>
        <div className={s.card_list_wrapper}>
          {awards && <CardsList tasks={awards} />}
        </div>
        {totalAwardsPoints > 0 && (
          <AwardsSubmitButton onClick={submitReceiveGifts} buttonText={localization.AwardsPage.btnText[language]} />
        )}
      </div>

      <Footer />
    </div>
  );
};

export default AwardsPage;
