import types from "../types";

const initialState = {
  startWeekDate: "",
  endWeekDate: "",
  rewardsGained: 0,
  rewardsPlanned: 0,
  id: "",
};

const weekReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SUCCESS_REGISTER:
    case types.SUCCESS_LOGIN:
    case types.SUCCESS_GET_USER:
      return {
        ...state,
        startWeekDate: payload.userData.week.startWeekDate,
        endWeekDate: payload.userData.week.endWeekDate,
        rewardsGained: payload.userData.week.rewardsGained,
        rewardsPlanned: payload.userData.week.rewardsPlanned,
        id: payload.userData.week._id,
      };

    case types.ERROR_REGISTER:
    case types.ERROR_LOGIN:
    case types.SUCCESS_LOGOUT:
    case types.ERROR_GET_USER:
      return initialState;

    case types.SUCCESS_PATCH_ALL_TASKS_DAYS:
      return {
        ...state,
        rewardsPlanned: payload.updatedWeekPlannedRewards,
      };

    case types.SUCCESS_PATCH_SINGLE_TASK_DAYS:
      return {
        ...state,
        rewardsPlanned: payload.updatedWeekPlannedRewards,
      };

    case types.SUCCESS_SWITCH_TASK_DAY:
      return {
        ...state,
        rewardsGained: payload.updatedWeekGainedRewards,
      };

    default:
      return state;
  }
};

export default weekReducer;
