import types from '../types';

// patch all tasks days
export const startPatchAllTasksDaysAction = () => ({
  type: types.START_PATCH_ALL_TASKS_DAYS,
});
export const successPatchAllTasksDaysAction = data => ({
  type: types.SUCCESS_PATCH_ALL_TASKS_DAYS,
  payload: {
    updatedWeekPlannedRewards: data.updatedWeekPlannedRewards,
    updatedTasks: data.updatedTasks,
  },
});
export const errorPatchAllTasksDaysAction = err => ({
  type: types.ERROR_PATCH_ALL_TASKS_DAYS,
  payload: {
    error: err,
  },
});

// patch single task days
export const startPatchSingleTaskDaysAction = () => ({
  type: types.START_PATCH_SINGLE_TASK_DAYS,
});
export const successPatchSingleTaskDaysAction = data => ({
  type: types.SUCCESS_PATCH_SINGLE_TASK_DAYS,
  payload: {
    updatedWeekPlannedRewards: data.updatedWeekPlannedRewards,
    updatedTask: data.updatedTask,
  },
});
export const errorPatchSingleTaskDaysAction = err => ({
  type: types.ERROR_PATCH_SINGLE_TASK_DAYS,
  payload: {
    error: err,
  },
});

export const taskPlanningChangeAction = taskId_dayDateString => ({
  type: types.TASKS_PLANNING_CHANGE,
  payload: {
    taskId_dayDateString,
  },
});

// switch task day
export const startSwitchTaskDayAction = () => ({
  type: types.START_SWITCH_TASK_DAY,
});
export const successSwitchTaskDayAction = data => ({
  type: types.SUCCESS_SWITCH_TASK_DAY,
  payload: {
    updatedBalance: data.updatedBalance,
    updatedWeekGainedRewards: data.updatedWeekGainedRewards,
    updatedTask: data.updatedTask,
  },
});
export const errorSwitchTaskDayAction = err => ({
  type: types.ERROR_SWITCH_TASK_DAY,
  payload: {
    error: err,
  },
});

// create custom task
export const startCreateTaskAction = () => ({
  type: types.START_CREATE_TASK,
});
export const successCreateTaskAction = data => ({
  type: types.SUCCESS_CREATE_TASK,
  payload: {
    _id: data.id,
    imageUrl: data.imageUrl,
    title: data.title,
    reward: data.reward,
    days: data.days
  }
});
export const errorCreateTaskAction = err => ({
  type: types.ERROR_CREATE_TASK,
  payload: {
    error: err,
  },
});