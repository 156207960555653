import { combineReducers } from "redux";
import types from "../types";

const initialUserReducer = {
  email: "",
  balance: 0,
  id: "",
  name: "",
  avatarUrl: "",
};

const userReducer = (state = initialUserReducer, { type, payload }) => {
  switch (type) {
    case types.SUCCESS_REGISTER:
    case types.SUCCESS_LOGIN:
      return ({
        ...payload.userData.user
      });

    case types.ERROR_REGISTER:
    case types.ERROR_LOGIN:
    case types.SUCCESS_LOGOUT:
    case types.ERROR_GET_USER:
      return null;

    case types.SUCCESS_GET_USER:
      return ({
        ...payload.userData.user
      });

    case types.SUCCESS_SWITCH_TASK_DAY:
      return ({
        ...state,
        balance: payload.updatedBalance,
      });

    case types.SUCCESS_RECEIVE_GIFTS:
      return ({
        ...state,
        balance: payload.data.updatedBalance,
      })

    default:
      return state;
  }
};

const tokenReducer = (state = null, { type, payload }) => {
  switch (type) {
    case types.SUCCESS_REGISTER:
    case types.SUCCESS_LOGIN:
      return payload.userData.token;

    case types.ERROR_REGISTER:
    case types.ERROR_LOGIN:
    case types.SUCCESS_LOGOUT:
      return null;

    case types.SET_TOKEN_IN_STORE:
      return payload.token;

    default:
      return state;
  }
};

const initialErrorReducer = { message: "" };

const errorReducer = (state = initialErrorReducer, { type, payload }) => {
  switch (type) {
    case types.SUCCESS_REGISTER:
    case types.SUCCESS_LOGIN:
    case types.SUCCESS_LOGOUT:
    case types.SUCCESS_GET_USER:
      return initialErrorReducer;

    case types.ERROR_REGISTER:
    case types.ERROR_LOGIN:
    case types.ERROR_LOGOUT:
    case types.ERROR_GET_USER:
      return payload.error;

    default:
      return state;
  }
};

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case types.START_REGISTER:
    case types.START_LOGIN:
    case types.START_LOGOUT:
    case types.START_GET_USER:
      return true;

    case types.SUCCESS_REGISTER:
    case types.SUCCESS_LOGIN:
    case types.SUCCESS_LOGOUT:
    case types.SUCCESS_GET_USER:
      return false;

    case types.ERROR_REGISTER:
    case types.ERROR_LOGIN:
    case types.ERROR_LOGOUT:
    case types.ERROR_GET_USER:
      return false;

    default:
      return state;
  }
};

const isAuthReducer = (state = false, { type }) => {
  switch (type) {
    case types.SUCCESS_REGISTER:
    case types.SUCCESS_LOGIN:
    case types.SUCCESS_GET_USER:
      return true;

    case types.SUCCESS_LOGOUT:
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  user: userReducer,
  token: tokenReducer,
  error: errorReducer,
  isLoading: loadingReducer,
  isAuth: isAuthReducer,
});
