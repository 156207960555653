import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as authOperations from "../../redux/auth/authOperation";
import * as authSelectors from '../../redux/auth/authSelectors';
import Form from "../Form/Form";
import Input from "../Input/Input";
import AuthButton from "../AuthButton/AuthButton";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";
import { ReactComponent as IconGoogle } from '../../assets/icons/google_icon/google_icon.svg';
import s from "./AuthForm.module.css";

const AuthForm = () => {
  const { data } = useData();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(localization.AuthForm.errors.emailRequiredField[data.language])
      .email(localization.AuthForm.errors.isNotEmail[data.language]),
    password: yup
      .string()
      .required(
        localization.AuthForm.errors.passwordRequiredField[data.language]
      )
      .min(
        5,
        localization.AuthForm.errors.passwordMustContain5Symbols[data.language]
      )
      .max(
        30,
        localization.AuthForm.errors.passwordMustContainMaximum30Symbols[
          data.language
        ]
      ),
  });

  const [pressedButtonName, setPressedButtonName] = useState("");

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const onSubmitForm = (formData) => {
    //delay for the pressedButtonName variable to be overwritten:
    setTimeout(() => {
      if (pressedButtonName === "login") {
        dispatch(authOperations.loginOperation(formData, data.language))
      } else if (pressedButtonName === "register") {
        dispatch(authOperations.registerOperation(formData, data.language))
      }
    }, 300)
  };

  const isAuthErrorMessage = useSelector(store => authSelectors.getIsAuthErrorMessage(store));

  return (
    <div className={s.authFormContainer}>
      <p className={s.text}>{localization.AuthForm.logInWithGoogleText[data.language]}</p>
        <a className={s.googleLink} href="https://kidslike-v1-backend.goit.global/auth/google-en">
          <IconGoogle width="28" height="28" />
          <span className={s.linkText}>Google</span>
        </a>

      <p className={s.text}>{localization.AuthForm.enterWithEmailAndPasswordText[data.language]}</p>

      <Form onSubmit={handleSubmit(onSubmitForm)}>
        <Input
          ref={register}
          id="email"
          type="email"
          label={localization.AuthForm.inputEmailLabel[data.language]}
          name="email"
          required
          error={!!errors.email}
          helperText={errors?.email?.message}
        />
        <Input
          ref={register}
          id="password"
          type="password"
          label={localization.AuthForm.inputPasswordLabel[data.language]}
          name="password"
          required
          error={!!errors.password}
          helperText={errors?.password?.message}
        />
        <div className={s.btn_container}>
          <AuthButton onClickFunc={() => setPressedButtonName("login")}>
            {localization.AuthForm.buttons.enter[data.language]}
          </AuthButton>
          <AuthButton onClickFunc={() => setPressedButtonName("register")}>
            {localization.AuthForm.buttons.register[data.language]}
          </AuthButton>
        </div>
      </Form>
      <p className={s.error}>{isAuthErrorMessage}</p>
    </div>
  );
};

export default AuthForm;
