import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import moment from "moment";
import s from "./CardsList.module.css";
import Card from '../Card/Card';
import shortid from 'shortid';

import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";

const momentObj = moment();
const dayNow = momentObj.isoWeekday();
let url;

const CardList = ({ tasks }) => {
  //  function that finds tasks for today, but only if we are on the main page - start:
  const { search, pathname } = useLocation();
  const currentCards = (cardsArr) => {
    if (pathname === "/") {
      const result = [];
      const urlDay = new URLSearchParams(search).get("day");

      if (urlDay) {
        url = momentObj.day(urlDay).isoWeekday();

        cardsArr.forEach((el) => {
          const { _id, title, imageUrl, reward } = el;
          const day = el.days[url - 1];
          const transit = { ...day, _id, title, imageUrl, reward };

          day.isActive && result.push(transit);
        });
      }
      return result;
    }
    return cardsArr;
  };
  //  function that finds tasks for today, but only if we are on the main page - end:
  const cards = currentCards(tasks);

  const { data } = useData();
  const language = data.language;

  return cards.length ? (
    <ul className={s.cards_list}>
      {cards.map((el) => {
        return (
          <li key={shortid.generate()} className={s.card}>
            <Card {...el} />
          </li>
        );
      })}
    </ul>
  ) : (
    <div className={s.cards_list_planer}>
      {dayNow <= url && (
        <>
          <h2 className={s.no_tasks}>
            {localization.CardList.noTasksForToday[language]}
          </h2>

          <NavLink to="/planning">
            <button type="button" className={s.redirect_to_planer}>
              {localization.CardList.sheduleNewTasks[language]}
            </button>
          </NavLink>
        </>
      )}
    </div>
  );
};

export default CardList;
