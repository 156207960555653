import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import WeekTabsMobile from "../../components/WeekTabsMobile/WeekTabsMobile";
import WeekTabsTablet from "../../components/WeekTabsTablet/WeekTabsTablet";
import WeekTabsDesktop from "../../components/WeekTabsDesktop/WeekTabsDesktop";
import WeekTabsContent from "../../components/WeekTabsContent/WeekTabsContent";
import Footer from '../../components/Footer/Footer';

import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";
import s from "./MainPage.module.css";

const setMainPath = (daysArr) => {
  const weekDay = moment().get("day") || 7;
  if (weekDay >= 0) {
    const stringDay = daysArr[weekDay - 1].url;
    return `?day=${stringDay}`;
  }
  return "";
};

const MainPage = () => {
  const { data } = useData();
  const language = data.language;

  const days = localization.MainPage.days.map((el) => ({
    ...el,
    name: el.name[language],
    shortName: el.shortName[language],
  }));

  const day = setMainPath(days);
  const history = useHistory();

  useEffect(() => {
    history.push(day);
  }, [day, history]);

  return (
    <div className={s.mainDiv}>
      {/* WeekTabsMobile shows with css rule on interval 0px to 767px: */}
      <WeekTabsMobile days={days} />

      {/* WeekTabsTablet shows with css rule on interval 768px to 1279px: */}
      <WeekTabsTablet days={days} />

      {/* WeekTabsTablet shows with css rule on interval 1280px to infiniti */}
      <WeekTabsDesktop days={days} />

      <WeekTabsContent />

      <Footer />
    </div>
  );
};

export default MainPage;
