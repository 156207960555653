import React, { useState } from "react";
import { validateAll } from "indicative/validator";
import s from "./NewTaskModal.module.css";
import ModalBackdropWrapper from "../ModalBackdropWrapper/ModalBackdropWrapper";
import { useForm } from "react-hook-form";
import FileInput from "../FileInput/FileInput";
import imageRobot from "../../assets/images/imageRobot.png";
import { ReactComponent as Cancel } from "../../assets/icons/close/cancel.svg";
import { ReactComponent as Edit } from "../../assets/icons/icon edit/edit-24px.svg";
import { useData } from "../../context/DataContext";
import localization from "../../utils/localization";
import { createTaskOperation } from "../../redux/tasks/tasksOperation";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const NewTaskModal = ({ onClose }) => {
  const { control, handleSubmit } = useForm();

  const { data } = useData();
  const language = data.language;

  const validationRules = {
    title: "max:20|required",
    taskPoints: "number|integer|range:1,50|required",
  };
  const validationMessages = {
    "title.required": localization.NewTaskModal.titleRequired[language],
    "taskPoints.required": localization.NewTaskModal.titleRequired[language],
    "title.max": localization.NewTaskModal.titleMax[language],
    "taskPoints.range": localization.NewTaskModal.taskPointsRange[language],
  };

  const [title, setTitle] = useState("");
  const handleChangeTitle = (e) => setTitle(e.target.value);

  const [taskPoints, setTaskPoints] = useState("");
  const handleChangeTaskPoints = (e) => setTaskPoints(e.target.value);

  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const onSubmitForm = (filesData) => {

    validateAll({ title, taskPoints }, validationRules, validationMessages)
      .then((data) => {
        if (filesData.files[0]) {
          if (
            filesData.files[0].type === "image/jpeg" ||
            filesData.files[0].type === "image/png" ||
            filesData.files[0].type === "image/jpg" ||
            filesData.files[0].type === "image/svg+xml"
          ) {
            const oneMegabytes = 1006000;
            if (filesData.files[0].size < oneMegabytes) {
              const taskData = {
                title: data.title,
                reward: data.taskPoints,
                file: filesData.files[0],
              };
              dispatch(createTaskOperation(taskData));
              setTitle("");
              setTaskPoints("");
              setError(null);
              onClose();
            } else {
              toast.error(localization.NewTaskModal.fileSize[language]);
            }
          } else {
            toast.error(localization.NewTaskModal.fileFormat[language]);
          }
        } else {
          const taskData = {
            title: data.title,
            reward: data.taskPoints,
          };
          dispatch(createTaskOperation(taskData));
          setTitle("");
          setTaskPoints("");
          setError(null);
          onClose();
        }
      })
      .catch((errors) => {
        const formatedErrors = {};
        errors.forEach((error) => {
          formatedErrors[error.field] = error.message;
        });
        setError(formatedErrors);
      });
  };

  return (
    <ModalBackdropWrapper onClose={onClose}>
      <div className={s.taskModal}>
        <div className={s.taskImage}>
          <button type="button" className={s.taskCloseButton}>
            <Cancel onClick={onClose} />
          </button>
          <img src={imageRobot} alt="robot" />
        </div>

        <div className={s.taskForm}>
          <form className={s.form} onSubmit={handleSubmit(onSubmitForm)}>
            <p className={s.labelFileInput}>
              {localization.NewTaskModal.labelInputFile[language]}
            </p>
            <FileInput
                name="files"
                control={control}
                inputFileText={localization.NewTaskModal.inputFileText[language]}
              />

            <label htmlFor="text" className={s.textSection}>
              <Edit className={s.taskIconEdit} />
              <input
                className={s.taskInput}
                placeholder={
                  localization.NewTaskModal.addTaskTextPlaceholder[language]
                }
                type="text"
                name="title"
                value={title}
                onChange={handleChangeTitle}
              />
              {error && (
                <span className={`${s.titleError} ${s.error}`}>
                  {error.title}
                </span>
              )}
            </label>

            <label htmlFor="number" className={s.pointsSection}>
              <Edit className={s.gradeIconEdit} />
              <input
                className={s.taskPoints}
                type="number"
                value={taskPoints}
                name="taskPoints"
                placeholder={
                  localization.NewTaskModal.addScoreTextPlaceholder[language]
                }
                onChange={handleChangeTaskPoints}
              />
              {error && (
                <span className={`${s.pointError} ${s.error}`}>
                  {error.taskPoints}
                </span>
              )}
            </label>

            <button className={s.taskSubmitButton} type="submit">
              {localization.NewTaskModal.buttonCreateTask[language]}
            </button>
          </form>
        </div>
      </div>
    </ModalBackdropWrapper>
  );
};

export default NewTaskModal;
