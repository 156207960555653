import * as authActions from "./authActions";
import * as API from "../../services/api";
import localization from '../../utils/localization';

export const registerOperation = (registerData, language) => (dispatch) => {
  dispatch(authActions.startRegisterAction());

  API.registerUser(registerData)
    .then((res) => {
      if (res.data.success) {
        dispatch(authActions.successRegisterAction(res.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        const status = error.response.status;
        if (status === 409) {
          dispatch(
            authActions.errorRegisterAction({
              ...error.response.data,
              message: localization.AuthForm.errors.userWithEmailAlreadyExist[language],
            })
          );
        } else if (status === 400) {
          dispatch(
            authActions.errorRegisterAction(error.response.data)
          );
        } else {
          dispatch(authActions.errorRegisterAction(error.response.data));
        }
      } else {
        dispatch(authActions.errorRegisterAction(error));
      }
    });
};

export const loginOperation = (data, language) => (dispatch) => {
  dispatch(authActions.startLoginAction());

  API.loginUser(data)
    .then((res) => {
      if (res.data.success) {
        dispatch(authActions.successLoginAction(res.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        const status = error.response.status;
        if (status === 403) {
          if (error.response.data.message === "Password is wrong") {
            dispatch(
              authActions.errorLoginAction({
                ...error.response.data,
                message: localization.AuthForm.errors.passwordWrong[language],
              })
            );
          } else if(error.response.data.message === "User with this email doesn't exist") {
            dispatch(
              authActions.errorLoginAction({
                ...error.response.data,
                message: localization.AuthForm.errors.emailNotExist[language],
              })
            );
          } else {
            authActions.errorLoginAction(error.response.data)
          }
        } else if (status === 400) {
          dispatch(
            authActions.errorLoginAction(error.response.data)
          );
        } else {
          dispatch(authActions.errorLoginAction(error.response.data));
        }
      } else {
        dispatch(authActions.errorLoginAction(error));
      }
    });
};

export const logoutOperation = () => (dispatch, getState) => {
  dispatch(authActions.startLogoutAction());
  const state = getState();
  const { token } = state.auth;

  API.logoutUser(token)
    .then((res) => dispatch(authActions.successLogoutAction(res.data.user)))
    .catch((err) => dispatch(authActions.errorLogoutAction(err)));
};

export const getUserOperation = () => (dispatch, getState) => {
  const state = getState();
  const { token } = state.auth;

  if (!token) {
    return;
  }

  dispatch(authActions.startGetUserAction());
  API.getUser(token)
    .then((res) =>
      dispatch(authActions.successGetUserAction(res.data))
    )
    .catch((err) => dispatch(authActions.errorGetUserAction(err)));
};

export const getUserWithTokenFromUrlOperation = (tokenFromUrl) => (dispatch) => {
  dispatch(authActions.startGetUserAction());
  
  API.getUser(tokenFromUrl)
    .then((res) =>
      dispatch(authActions.successGetUserAction(res.data))
    )
    .catch((err) => dispatch(authActions.errorGetUserAction(err)));
};