import React from "react";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo/logo.svg";
import { ReactComponent as SmallLogoIcon } from "../../assets/icons/logo/smallLogo.svg";
import { Link } from "react-router-dom";
import s from "./Logo.module.css";

const Logo = ({smallLogo = false}) => {
  return (
    <Link className={s.link} to="/">
      {!smallLogo ? (
        <>
        <h1 className={s.bigLogoText}>KIDS LIKE</h1>
        <LogoIcon />
        </>
      ) : (
        <>
        <h2 className={s.smallLogoText}>Kids like</h2>
        <SmallLogoIcon />
        </>
      )}
    </Link>
  );
};

export default Logo;
